import styles from './Header.module.css';
import logo from '../Assets/logo.png';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Header() {
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;

        gtag('event', 'click', {
            'event_category': 'Header',
            'event_action': action,
        });
    }

    const handleOrderClick = () => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;
        
        gtag('event', 'purchase', {
            'event_category': 'Header',
            'event_action': 'Order Online',
            'value': 44.99,
            'currency': 'USD'
        });
    }

    useEffect(() => {
        let ticking = false;

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const percentage = Math.min(window.scrollY / (document.body.clientHeight - window.innerHeight) * 10, 1);
                    setScrollPercentage(percentage);
                    ticking = false;
                });
                ticking = true;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const backgroundColor = `rgba(10, 11, 10, ${scrollPercentage})`;

    const date = new Date();
    const year = date.getFullYear();

    return (
        <header className={styles.header} style={{backgroundColor: backgroundColor}}>
            <div className={styles.left}>
                <Link to={"/"} onClick={() => handleButtonClick("Logo")}><img src={logo} className={styles.logo} alt="Logo"/></Link>
            </div>
            <div className={styles.center}>
                <Link className={styles.navText} to={"/"} onClick={() => handleButtonClick("Home")}>HOME</Link>
                <Link className={styles.navText} to={"/menus"} onClick={() => handleButtonClick("Menus")}>MENUS</Link>
                <Link className={styles.navText} to={"/gallery"} onClick={() => handleButtonClick("Gallery")}>GALLERY</Link>
            </div>
            <div className={styles.right}>
                <a href="https://www.opendining.net/menu/5f219932505ee9aa4b7b241d" target="_blank" className={styles.navButton} onClick={() => handleOrderClick()}>ORDER ONLINE</a>
            </div>
            <div className={styles.mobileNav}>
                <Link to={"/"} onClick={() => handleButtonClick("Logo")}><img src={logo} className={styles.mobileLogo} alt="Logo"/></Link>
                <svg xmlns="http://www.w3.org/2000/svg" height="28px" fill="white" viewBox="0 0 448 512" onClick={() => {handleButtonClick("Open Mobile Nav"); setShowMobileMenu(!showMobileMenu);}}><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
            </div>
            {showMobileMenu && <div className={styles.mobileMenuBackdrop} />}
            <div className={styles.mobileMenu} style={{transform: showMobileMenu ? "translateX(0)" : undefined}}>
                <div className={styles.mobileMenuHeader}>
                    <Link to={"/"} onClick={() => handleButtonClick("Logo")}><img src={logo} className={styles.mobileLogo} alt="Logo"/></Link>
                    <svg xmlns="http://www.w3.org/2000/svg" height="28px" fill="white" viewBox="0 0 448 512" onClick={() => {handleButtonClick("Close Mobile Nav"); setShowMobileMenu(!showMobileMenu);}}><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
                </div>
                <div className={styles.mobileMenuBody}>
                    <a href="https://www.opendining.net/menu/5f219932505ee9aa4b7b241d" target="_blank" className={styles.mobileMenuOption} onClick={() => handleOrderClick()}>Order Online</a>
                    <Link className={styles.mobileMenuOption} to={"/"} onClick={() => handleButtonClick("Home")}>Home</Link>
                    <Link className={styles.mobileMenuOption} to={"/menus"} onClick={() => handleButtonClick("Menus")}>Menus</Link>
                    <Link className={styles.mobileMenuOption} to={"/gallery"} onClick={() => handleButtonClick("Gallery")}>Gallery</Link>
                </div>
                <div className={styles.socials}>
                    <a href="https://www.facebook.com/profile.php?id=100054552965621" target="_blank" rel="noopener noreferrer" aria-label='Visit our Facebook profile' onClick={() => handleButtonClick("Facebook")}>
                        <svg className={styles.social} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 512 512" fill="white"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg>
                    </a>
                </div>
                <p className={styles.copyright}>{`©${year} Blue Agave Restaurant`}</p>
                <div className={styles.contact}>
                    <div className={styles.contactOption}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 384 512" fill="white"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
                        <a 
                            href="https://www.google.com/maps?daddr=550+New+Airport+Rd,+Fletcher,+NC+28732" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            aria-label="Get directions to Blue Agave on Google Maps"
                            className={styles.contactText}
                            onClick={() => handleButtonClick("Directions")}
                        >
                            550 New, Airport Rd, Fletcher, NC 28732
                        </a>
                    </div>
                    <div className={styles.contactOption}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 512 512" fill='white'><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
                        <a 
                            href="tel:+18286763101" 
                            aria-label="Call Blue Agave"
                            className={styles.contactText}
                            onClick={() => handleButtonClick("Call")}
                        >
                            (828) 676-3101
                        </a>
                    </div>
                    <div className={styles.contactOption}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 512 512" fill="white"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
                        <a 
                            href="mailto:blue.agave.mexican.nc@gmail.com" 
                            aria-label="Email Blue Agave"
                            className={styles.contactText}
                            onClick={() => handleButtonClick("Email")}
                        >
                            blue.agave.mexican.nc@gmail.com
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;