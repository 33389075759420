import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Landing from './Pages/Landing';
import { useEffect } from 'react';
import Menu from './Pages/Menu';
import Menus from './Pages/Menus';
import Gallery from './Pages/Gallery';

function App() {
    const navigate = useNavigate();
    
    function Redirect() {
        useEffect(() => {
            navigate("/");
        }, []);
        return null;
    }

    return (
        <main className="app">
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/menus" element={<Menus />} />
                <Route path="/menus/:id" element={<Menu />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="*" element={<Redirect />} />
            </Routes>
        </main>
    );
}

export default App;
