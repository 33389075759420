import { useEffect, useState } from 'react';
import styles from './Footer.module.css';
import PrivacyPolicy from './PrivacyPolicy';
import Cookies from './Cookies';

function Footer() {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showCookies, setShowCookies] = useState(false);

    const date = new Date();
    const year = date.getFullYear();

    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Footer',
            'event_action': action,
        });
    }

    return (
        <footer className={styles.footer}>
            <div className={styles.upper}>
                <div className={styles.option}>
                    <p className={styles.title}>ADDRESS</p>
                    <a 
                        href="https://www.google.com/maps?daddr=550+New+Airport+Rd,+Fletcher,+NC+28732" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        aria-label="Get directions to 550 New, Airport Rd, Fletcher, NC 28732"
                        className={styles.desc}
                        onClick={() => handleButtonClick("Directions")}
                    >
                        550 New, Airport Rd, Fletcher, NC 28732
                    </a>
                </div>
                <div className={styles.option}>
                    <p className={styles.title}>PHONE</p>
                    <a 
                        href="tel:+18286763101" 
                        aria-label="Call Blue Agave's phone number (828) 676-3101"
                        className={styles.desc}
                        onClick={() => handleButtonClick("Call")}
                    >
                        (828) 676-3101
                    </a>
                </div>
                <div className={styles.option}>
                    <p className={styles.title}>EMAIL</p>
                    <a 
                        href="mailto:blue.agave.mexican.nc@gmail.com" 
                        aria-label="Send an email to Blue Agave at blue.agave.mexican.nc@gmail.com"
                        className={styles.desc}
                        onClick={() => handleButtonClick("Email")}
                    >
                        blue.agave.mexican.nc@gmail.com
                    </a>
                </div>
                <div className={styles.option}>
                    <p className={styles.title}>FOLLOW</p>
                    <div className={styles.socials}>
                    <a href="https://www.facebook.com/profile.php?id=100054552965621" target="_blank" rel="noopener noreferrer" aria-label='Visit our Facebook profile' onClick={() => handleButtonClick("Facebook")}>
                        <svg className={styles.social} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 512 512" fill="white">
                            <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/>
                        </svg>
                    </a>
                    </div>
                </div>
            </div>
            <div className={styles.lower}>
                {showPrivacyPolicy && <PrivacyPolicy close={() => setShowPrivacyPolicy(false)}/>}
                {showCookies && <Cookies close={() => setShowCookies(false)}/>}
                <div className={styles.footerLeft}>
                <a href="https://archetypeavl.com"
                    className={styles.footerText}
                    style={{cursor: "pointer"}} 
                    aria-label="Website by Archetype"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleButtonClick("Archetype")}
                    >
                    Website by Archetype
                </a>
                </div>
                <div className={styles.footerMid}>
                    <p className={styles.footerText}>{`©${year} Blue Agave Restaurant`}</p>
                </div>
                <div className={styles.footerRight}>
                        <p className={styles.footerRightText} onClick={() => {
                            handleButtonClick("Privacy Policy")
                            setShowPrivacyPolicy(true)
                        }}>Privacy Policy</p>
                        <p className={styles.footerRightText} onClick={() => {
                            handleButtonClick("Cookies")
                            setShowCookies(true)
                        }}>Manage Cookies</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;