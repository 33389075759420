import React, { useState, useEffect } from 'react';
import styles from './Cookies.module.css';

function Cookies(props: { close: (option?: string) => void; }) {
    const initialPreference = localStorage.getItem('cookiePreferenceBlueAgave') || 'On';

    const [cookiePreference, setCookiePreference] = useState<string>(initialPreference);

    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Cookies',
            'event_action': action,
        });
    }

    useEffect(() => {
        localStorage.setItem('cookiePreferenceBlueAgave', cookiePreference);
    }, [cookiePreference]);

    return (
        <div className={styles.cookiesBackdrop} onClick={() => props.close()}>
        <div className={styles.cookies} onClick={(e) => e.stopPropagation()}>
            <div className={styles.header}>
            <p className={styles.cookiesTitle}>Manage Cookies</p>
            <p className={styles.cookiesSubtitle}>We use cookies to improve the overall user experience.</p>
            </div>
            <div className={styles.body}>
            <p className={styles.bodyText}>All Cookies:</p>
            <div className={styles.bodyOption}>
                <p 
                    className={cookiePreference == 'Off' ? styles.bodyOptionSelected : styles.bodyOptionNon} 
                    onClick={() => {
                        handleButtonClick("Off")
                        setCookiePreference('Off')
                    }}
                >
                    Off
                </p>
                <p 
                    className={cookiePreference == 'On' ? styles.bodyOptionSelected : styles.bodyOptionNon} 
                    onClick={() => {
                        handleButtonClick("On")
                        setCookiePreference('On')
                    }}
                >
                    On
                </p>
            </div>
            </div>
            <div className={styles.footerButton} onClick={() => {
                props.close(cookiePreference)
            }}>CLOSE</div>
        </div>
        </div>
    );
}

export default Cookies;