import styles from './Contact.module.css';
import contact from '../Assets/interior.jpg';

function Contact() {
    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Contact',
            'event_action': action,
        });
    }

    return (
        <section className={styles.contact}>
            <div className={styles.inner}>
            <div className={styles.left}>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={contact} alt="Blue Agave"/>
                </div>
            </div>
            <div className={styles.right}>
                <p className={styles.subtitle}>LOCATION</p>
                <h2 className={styles.title}>VISIT US</h2>
                <div className={styles.section} style={{marginBottom: 48}}>
                    <p className={styles.subtitleSection}>WHERE</p>
                    <p className={styles.text} style={{marginBottom: 8}}>550 New, Airport Rd, Fletcher, NC 28732</p>
                    <p className={styles.text}>(828) 676-3101</p>
                    <div className={styles.buttonsContainer}>
                        <a href="tel:828-676-3101" aria-label="Call Now" className={styles.call} onClick={() => handleButtonClick("Call Now")}>CALL NOW</a>
                        <a 
                            href="https://www.google.com/maps/dir/?api=1&destination=550+New,+Airport+Rd,+Fletcher,+NC+28732" 
                            target="_blank" 
                            rel="noopener noreferrer"
                            aria-label="Get directions to our location: 550 New, Airport Rd, Fletcher, NC 28732"
                            className={styles.directions}
                            onClick={() => handleButtonClick("Get Directions")}
                        >GET DIRECTIONS</a>
                    </div>
                </div>
                <div className={styles.section}>
                    <p className={styles.subtitleSection}>WHEN</p>
                    <p className={styles.text} style={{marginBottom: 12}}>Monday - Saturday | 11am - 10pm</p>
                    <p className={styles.text}>Sunday | 11am - 9pm</p>
                </div>
            </div>
            </div>
        </section>
    )
}

export default Contact;