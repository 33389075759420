import { Helmet } from 'react-helmet';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import styles from './Gallery.module.css';
import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import menu from '../Assets/all.jpg';

function Gallery() {
    const [isLoading, setIsLoading] = useState(false);
    const [images, setImages] = useState<any>([]);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await fetch(`https://api.blueagavemx.com/api/galleries?populate=*`);
            const data = await res.json();
            setIsLoading(false);
            setImages(data.data[0].attributes.Media.data);
        } catch (error) {
            console.log("Error fetching gallery");
        }
    }

    const override: any = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate3d(-50%, -50%, 0)"
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <section className={styles.gallery}>
            <Helmet>
                <title>Blue Agave | Gallery</title>
                <meta 
                    name="description" 
                    content="Step into the vibrant world of Blue Agave through our gallery, showcasing the Mexican cuisine and lively atmosphere of our Fletcher, NC restaurant. Browse through photos of our delicious dishes, inviting interior, and festive events, and get a taste of the unforgettable experiences awaiting you near Asheville Regional Airport." 
                />
                <link rel="canonical" href="https://blueagavemx.com/gallery" />
            </Helmet>
            <Header />
            <div className={styles.hero}>
                <h1 className={styles.title}>Blue Agave's Gallery</h1>
                <div className={styles.heroBackgroundContainer}>
                    <img src={menu} className={styles.heroBackground} alt="Background"/>
                </div>
            </div>
            <div className={styles.body}>
                {isLoading && <PulseLoader color="white" cssOverride={override}/>}
                {images.length > 0 && <div className={styles.images}>
                    {images.map((el: any, idx: any) => (
                        <div className={styles.imageContainer} key={idx}>
                            <img src={el.attributes.url} className={styles.image} alt="Gallery Image"/>
                        </div>
                    ))}
                </div>}
            </div>
            <Footer />
        </section>
    )
}

export default Gallery;