import styles from './Hero.module.css'
import hero from '../Assets/hero.jpg';
import hero0 from '../Assets/hero0.jpg';
import hero1 from '../Assets/hero1.jpg';
import hero2 from '../Assets/hero2.jpg';
import hero3 from '../Assets/hero3.jpg';
import hero4 from '../Assets/hero4.jpg';
import dd from '../Assets/dd.png';
import all from '../Assets/all.jpg';
import { useEffect, useState } from 'react';

function Hero() {
    const [activeImage, setActiveImage] = useState(0);
    const images = [hero, all, hero0, hero1, hero2, hero3, hero4];

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveImage((prevActiveImage) => (prevActiveImage + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const handleOrderClick = () => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;
        
        gtag('event', 'purchase', {
            'event_category': 'Hero',
            'event_action': 'Order Online',
            'value': 44.99,
            'currency': 'USD'
        });
    }

    return (
        <section className={styles.hero}>
            <div className={styles.heroLeft}>
                <h1 className={styles.title}>MEXICAN RESTAURANT IN FLETCHER, NC</h1>
                <p className={styles.subtitle}>WELCOME TO BLUE AGAVE</p>
                <div className={styles.hours}>
                    <p className={styles.option}>MONDAY - SATURDAY | 11am - 10pm</p>
                    <p className={styles.option}>SUNDAY | 11am - 9pm</p>
                </div>
                <div className={styles.buttons}>
                    <a href="https://www.opendining.net/menu/5f219932505ee9aa4b7b241d" target="_blank" className={styles.cta} onClick={() => handleOrderClick()}>ORDER ONLINE</a>
                    <a href="https://order.online/store/blue-agave-fletcher-895148/?hideModal=true&pickup=true" target="_blank" className={styles.dd} onClick={() => handleOrderClick()}>
                        <img src={dd} className={styles.ddLogo} alt="DoorDash Logo"/>
                        ORDER DOORDASH
                    </a>
                </div>
            </div>
            <div className={styles.heroBackgroundContainer}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        className={`${styles.heroBackground} ${activeImage === index ? styles.active : ''}`}
                        alt="Background"
                    />
                ))}
            </div>
        </section>
    )
}

export default Hero;