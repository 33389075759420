import styles from './Explore.module.css';
import sprinkle from '../Assets/enchilada.jpg';
import dinner from '../Assets/dinner.jpg';
import drink from '../Assets/drinks.jpg';
import { Link } from 'react-router-dom';

function Explore() {
    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Explore Menus',
            'event_action': action,
        });
    }

    return (
        <section className={styles.explore}>
            <div className={styles.inner}>
            <p className={styles.subtitle}>EXPLORE</p>
            <h2 className={styles.title}>OUR MENUS</h2>
            <div className={styles.body}>
                <Link className={styles.option} to={"/menus/lunch"} onClick={() => handleButtonClick("Lunch")}>
                    <div className={styles.imageContainer}>
                        <img src={sprinkle} className={styles.image} alt="Molcajete"/>
                    </div>
                    <h2 className={styles.name}>LUNCH</h2>
                </Link>
                <Link className={styles.option} to={"/menus/dinner"} onClick={() => handleButtonClick("Dinner")}>
                    <div className={styles.imageContainer}>
                        <img src={dinner} className={styles.image} alt="Tostadas"/>
                    </div>
                    <h2 className={styles.name}>DINNER</h2>
                </Link>
                <Link className={styles.option} to={"/menus/drinks"} onClick={() => handleButtonClick("Drinks")}>
                    <div className={styles.imageContainer}>
                        <img src={drink} className={styles.image} alt="Yellow Margarita"/>
                    </div>
                    <h2 className={styles.name}>DRINKS</h2>
                </Link>
            </div>
            </div>
        </section>
    )
}

export default Explore;