import { Helmet } from 'react-helmet';
import styles from './Menus.module.css';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import sprinkle from '../Assets/enchilada.jpg';
import dinner from '../Assets/dinner.jpg';
import drink from '../Assets/drinks.jpg';
import menu from '../Assets/all.jpg';
import { Link } from 'react-router-dom';

function Menus() {
    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Menus Page',
            'event_action': action,
        });
    }

    return (
        <section className={styles.menus}>
            <Helmet>
                <title>Blue Agave | Menus</title>
                <meta 
                    name="description" 
                    content="Discover the diverse menus at Blue Agave, offering a rich array of Mexican dishes in Fletcher, NC. From savory tacos and enchiladas to refreshing margaritas, explore our culinary delights and find your favorite flavors. Perfectly situated near Asheville Regional Airport, we are your go-to destination for a memorable dining experience." 
                />
                <link rel="canonical" href="https://blueagavemx.com/menus" />
            </Helmet>
            <Header />
            <div className={styles.hero}>
                <h1 className={styles.title}>Blue Agave's Menus</h1>
                <div className={styles.heroBackgroundContainer}>
                    <img src={menu} className={styles.heroBackground} alt="Background"/>
                </div>
            </div>
            <div className={styles.body}>
                <Link className={styles.option} to={"/menus/lunch"} onClick={() => handleButtonClick("Lunch")}>
                    <div className={styles.imageContainer}>
                        <img src={sprinkle} className={styles.image} alt="Background"/>
                    </div>
                    <h2 className={styles.name}>LUNCH</h2>
                </Link>
                <Link className={styles.option} to={"/menus/dinner"} onClick={() => handleButtonClick("Dinner")}>
                    <div className={styles.imageContainer}>
                        <img src={dinner} className={styles.image} alt="Background"/>
                    </div>
                    <h2 className={styles.name}>DINNER</h2>
                </Link>
                <Link className={styles.option} to={"/menus/drinks"} onClick={() => handleButtonClick("Drinks")}>
                    <div className={styles.imageContainer}>
                        <img src={drink} className={styles.image} alt="Background"/>
                    </div>
                    <h2 className={styles.name}>DRINKS</h2>
                </Link>
            </div>
            <Footer />
        </section>
    )
}

export default Menus;