import { Helmet } from 'react-helmet';
import styles from './Menu.module.css';
import Header from '../Components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import Footer from '../Components/Footer';
import dinner from '../Assets/hero1.jpg';
import lunch from '../Assets/enchilada.jpg';
import drinks from '../Assets/drinks.jpg';

function Menu() {
    const { id } = useParams();

    const [menuSectionsLeft, setMenuSectionsLeft] = useState<any[]>([]);
    const [menuSectionsRight, setMenuSectionsRight] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleButtonClick = (action: string) => {
        if (localStorage.getItem('cookiePreferenceBlueAgave') == "Off") return;
        
        gtag('event', 'click', {
            'event_category': 'Menu Page',
            'event_action': action,
        });
    }

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await fetch(`https://api.blueagavemx.com/api/menus?populate=sections.items`);
            const data = await res.json();
            setIsLoading(false);
    
            if (data && data.data) {
                let validId = false;
    
                data.data.forEach((el: any, idx: any) => {
                    if (el?.attributes?.name?.toLowerCase() == id?.toLowerCase()) {
                        const totalItems = el.attributes.sections.data.reduce((acc: any, section: any) => acc + section.attributes.items.data.length, 0);
                        
                        const leftSections: any[] = [];
                        const rightSections: any[] = [];
                        let currentTotalItems = 0;
    
                        el.attributes.sections.data.forEach((section: any) => {
                            if (currentTotalItems + section.attributes.items.data.length <= totalItems / 2) {
                                leftSections.push(section);
                                currentTotalItems += section.attributes.items.data.length;
                            } else {
                                rightSections.push(section);
                            }
                        });
    
                        setMenuSectionsLeft(leftSections);
                        setMenuSectionsRight(rightSections);
                        validId = true;
                    }
                })
    
                if (!validId) navigate("/menus");
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };    
    
    useEffect(() => {
        fetchData();
    }, [id]);

    const override: any = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate3d(-50%, -50%, 0)"
    };

    return (
        <section className={styles.menu}>
            <Helmet>
                <title>{`Blue Agave | ${id!.charAt(0).toUpperCase() + id!.slice(1)} Menu`}</title>
                <meta 
                    name="description"
                    content="Explore our menu at Blue Agave, Fletcher, NC’s premier destination for Mexican cuisine. Savor a variety of traditional and innovative dishes, from tantalizing tacos and enchiladas to signature cocktails. Perfect for both locals and visitors seeking a unique dining experience near Asheville Regional Airport."
                />  
                <link rel="canonical" href={`https://blueagavemx.com/menus/${id}`} />
            </Helmet>
            <Header />
            <div className={styles.hero}>
                <h1 className={styles.title}>{`Our ${id!.charAt(0).toUpperCase() + id!.slice(1)} Menu`}</h1>
                <div className={styles.heroBackgroundContainer}>
                    <img src={id == "lunch" ? lunch : id == "dinner" ? dinner : id == "drinks" ? drinks : dinner} className={styles.heroBackground} alt="Background"/>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.options}>
                    <Link to={"/menus/lunch"} className={`${styles.option} ${id?.toLowerCase() == "lunch" ? styles.selected : ""}`} onClick={() => handleButtonClick("Lunch")}>LUNCH</Link>
                    <Link to={"/menus/dinner"} className={`${styles.option} ${id?.toLowerCase() == "dinner" ? styles.selected : ""}`} onClick={() => handleButtonClick("Dinner")}>DINNER</Link>
                    <Link to={"/menus/drinks"} className={`${styles.option} ${id?.toLowerCase() == "drinks" ? styles.selected : ""}`} onClick={() => handleButtonClick("Drinks")}>DRINKS</Link>
                </div>
                <div className={styles.menuBody}>
                    {isLoading && <PulseLoader color="white" cssOverride={override}/>}
                    {!isLoading && <div className={styles.left}>
                        {menuSectionsLeft.map((el, idx) => (
                            <div className={styles.section} key={idx}>
                                <h2 className={styles.sectionTitle}>{el.attributes.name}</h2>
                                {el.attributes.items.data.map((el: any, idx: any) => (
                                    <div className={styles.optionMenu} key={idx}>
                                        <div className={styles.optionUpper}>
                                            <p className={styles.name}>{el.attributes.title}</p>
                                            <p className={styles.price}>{el.attributes.price}</p>
                                        </div>
                                        <p className={styles.desc}>{el.attributes.desc}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>}
                    {!isLoading && <div className={styles.right}>
                        {menuSectionsRight.map((el, idx) => (
                            <div className={styles.section} key={idx}>
                                <h2 className={styles.sectionTitle}>{el.attributes.name}</h2>
                                {el.attributes.items.data.map((el: any, idx: any) => (
                                    <div className={styles.optionMenu} key={idx}>
                                        <div className={styles.optionUpper}>
                                            <p className={styles.name}>{el.attributes.title}</p>
                                            <p className={styles.price}>{el.attributes.price}</p>
                                        </div>
                                        <p className={styles.desc}>{el.attributes.desc}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default Menu;