import { useState } from 'react';
import styles from './Reviews.module.css';

function Reviews() {
    const [mainIdx, setMainIdx] = useState(0);

    function Star() {
        return (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 426.667 426.667" height="18px"><polygon points="213.333,10.441 279.249,144.017 426.667,165.436 320,269.41 345.173,416.226 213.333,346.91 81.485,416.226 106.667,269.41 0,165.436 147.409,144.017 " fill="#ffc800"/></svg>
        )
    }

    const reviews = [
        {
            name: "Mike Scofield | Google Review",
            desc: "Blue Agave is our family's favorite local Mexican food restaurant. We have a few restaurants closer to our house, but drive a little further for these guys. Great service and delicious food everytime. They provide a bean dip and a self-serve salsa bar with your basket of chips and the serving sizes leave you with leftovers so you can enjoy the meal more than once! Cheers!"
        },
        {
            name: "John Zombie | Google Review",
            desc: "The BEST Mexican restaurant around hands down! This place is Ballin! #fire #love The server was on point and funny! I would recommend this restaurant to everyone! The best dine-in we have had in a long time!"
        },
        {
            name: "Djahlin Ashley | Google Review",
            desc: "We found this restaurant on google and decided to try it because it was straight across from the hotel. When we walked in, we were instantly greeted with a friendly host. Seated pretty quickly. The staff were very friendly and very quick but efficient. I would definitely recommend this restaurant for dinner. Family friendly! Food was amazing!!!"
        },
        {
            name: "Tara Davis | Google Review",
            desc: "I just realized that I have not left a rave on Google for this amazing restaurant! My whole family loves the food, every time I order whether it's dine in or Door Dash it's hot and delicious, and the margaritas are to die for. If you're on the fence, DO IT."
        },
        {
            name: "Jennifer Hinson | Google Review",
            desc: "We went to Blue Agave for the first time today. My hubby wanted to try something new for his birthday dinner. We definitely had a better experience than either of us imagined! Our waiter was awesome, the drinks were fantastic, the atmosphere was very pleasant, and the food was off the charts superb!!! This is single handedly the best Mexican food I have EVER put in my mouth...my hubby barely spoke the entire meal because he was enjoying it so much. I would highly recommend to go here if you are desiring mouth watering goodness!! This is definitely my top choice of anywhere we have been in a very long time!!"
        },
    ]

    return (
        <section className={styles.reviews}>
            <div className={styles.inner}>
            <div className={styles.titleContainer}>
                <p className={styles.subtitle}>REVIEWS</p>
                <h2 className={styles.title}>TALK OF THE TOWN</h2>
                <div className={styles.arrows}>
                    <svg className={styles.arrow} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" height="32px" onClick={() => setMainIdx(prev => (prev - 1 + reviews.length) % reviews.length)}><path d="M31.106,15H3.278l8.325-8.293  c0.391-0.391,0.391-1.024,0-1.414c-0.391-0.391-1.024-0.391-1.414,0l-9.9,9.899c-0.385,0.385-0.385,1.029,0,1.414l9.9,9.9  c0.391,0.391,1.024,0.391,1.414,0c0.391-0.391,0.391-1.024,0-1.414L3.278,17h27.828c0.552,0,1-0.448,1-1  C32.106,15.448,31.658,15,31.106,15z" fill="white"/></svg>
                    <svg className={styles.arrow} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" height="32px" onClick={() => setMainIdx(prev => (prev + 1) % reviews.length)}><path d="M32,16.009c0-0.267-0.11-0.522-0.293-0.714  l-9.899-9.999c-0.391-0.395-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428l8.193,8.275H1c-0.552,0-1,0.452-1,1.01  s0.448,1.01,1,1.01h27.586l-8.192,8.275c-0.391,0.394-0.39,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l9.899-9.999  C31.894,16.534,31.997,16.274,32,16.009z" fill="white"/></svg>
                </div>
            </div>
            <div className={styles.review}>
                <p className={styles.desc}>{reviews[mainIdx].desc}</p>
                <div className={styles.stars}>
                    <Star/>
                    <Star/>
                    <Star/>
                    <Star/>
                    <Star/>
                </div>
                <p className={styles.name}>{reviews[mainIdx].name}</p>
                <div className={styles.mobileArrows}>
                    <svg className={styles.arrow} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" height="32px" onClick={() => setMainIdx(prev => (prev - 1 + reviews.length) % reviews.length)}><path d="M31.106,15H3.278l8.325-8.293  c0.391-0.391,0.391-1.024,0-1.414c-0.391-0.391-1.024-0.391-1.414,0l-9.9,9.899c-0.385,0.385-0.385,1.029,0,1.414l9.9,9.9  c0.391,0.391,1.024,0.391,1.414,0c0.391-0.391,0.391-1.024,0-1.414L3.278,17h27.828c0.552,0,1-0.448,1-1  C32.106,15.448,31.658,15,31.106,15z" fill="white"/></svg>
                    <svg className={styles.arrow} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" height="32px" onClick={() => setMainIdx(prev => (prev + 1) % reviews.length)}><path d="M32,16.009c0-0.267-0.11-0.522-0.293-0.714  l-9.899-9.999c-0.391-0.395-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428l8.193,8.275H1c-0.552,0-1,0.452-1,1.01  s0.448,1.01,1,1.01h27.586l-8.192,8.275c-0.391,0.394-0.39,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l9.899-9.999  C31.894,16.534,31.997,16.274,32,16.009z" fill="white"/></svg>
                </div>
            </div>
            </div>
        </section>
    )
}


export default Reviews;