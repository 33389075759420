import styles from './About.module.css';
import exterior from '../Assets/outdoor.jpg';

function About() {
    return (
        <section className={styles.about}>
            <div className={styles.inner}>
            <div className={styles.left}>
                <p className={styles.subtitle}>ABOUT US</p>
                <h2 className={styles.title}>A PIECE OF MEXICO</h2>
                <p className={styles.desc}>
                    Discover Blue Agave in Fletcher, NC, your go-to for Mexican cuisine less than a mile away from Asheville Regional Airport. Perfect for travelers and locals alike, our menu features signature margaritas, tacos, and more. Every visit is a flavor-packed journey through Mexico.
                </p>
            </div>
            <div className={styles.right}>
                <div className={styles.imageContainer}>
                    <img className={styles.image} src={exterior} alt="Blue Agave"/>
                </div>
            </div>
            </div>
        </section>
    )
}

export default About;