import { Helmet } from 'react-helmet';
import styles from './Landing.module.css';
import Header from '../Components/Header';
import Hero from '../Components/Hero';
import About from '../Components/About';
import Explore from '../Components/Explore';
import Reviews from '../Components/Reviews';
import Contact from '../Components/Contact';
import Footer from '../Components/Footer';

function Landing() {
    return (
        <section className={styles.landing}>
            <Helmet>
                <title>Blue Agave | Mexican Restaurant in Fletcher, NC</title>
                <meta 
                    name="description"
                    content="Experience the flavors of Mexico at Blue Agave, the premier Mexican Restaurant in Fletcher, NC. Enjoy our famous tacos, margaritas, and vibrant atmosphere for lunch and dinner. Conveniently located less than a mile from the Asheville Regional Airport."
                />  
                <link rel="canonical" href="https://blueagavemx.com" />
            </Helmet>
            <Header />
            <Hero />
            <About />
            <Explore />
            <Reviews />
            <Contact />
            <Footer />
        </section>
    )
}

export default Landing;